/* ============================================================================ *\
   Components -> Risky click
\* ============================================================================ */

/* Shake n' bake! */
@keyframes shakeshakeshake {
    0% {
        transform: translate(2px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-3deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(3deg);
    }

    30% {
        transform: translate(0px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(3deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-3deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(2px, 1px) rotate(-3deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(3deg);
    }

    90% {
        transform: translate(2px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-3deg);
    }
}

.risky-click {
    display: inline-block;
    cursor: pointer;
    cursor: var(--cursor-emoji-grimace);

    &,
    &:hover,
    &:visited {
        color: var(--red);
    }

    &:hover,
    &:focus {
        animation: shakeshakeshake 0.8s linear infinite;
        transform-origin: 50% 50;
    }
}
