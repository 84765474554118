/* ============================================================================ *\
   Components -> Break Out
   Element that breaks out of its container
\* ============================================================================ */

[data-breakout] {
    margin: var(--space) 0;

    &,
    & * {
        display: block;
        max-width: none;
        width: 100%;
    }
}

@media screen and (--large-and-up) {
    [data-breakout] {
        margin: var(--space-2x) 0;
    }
}
