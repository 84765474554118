/* ============================================================================ *\
   Components -> Taglist
\* ============================================================================ */

.lazyload,
.lazyloading {
    opacity: 0;
}

.lazyloaded {
    opacity: 1;
    transition: opacity 0.3s;
}
