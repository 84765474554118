/* ============================================================================ *\
   Components -> Icon
\* ============================================================================ */

.icon {
    width: var(--icon-size-base);
    height: var(--icon-size-base);
}

.icon--medium {
    width: var(--icon-size-medium);
    height: var(--icon-size-medium);
}

.icon--large {
    width: var(--icon-size-large);
    height: var(--icon-size-large);
}

.icon--xlarge {
    width: var(--icon-size-xlarge);
    height: var(--icon-size-xlarge);
}

.icon--xxlarge {
    width: var(--icon-size-xxlarge);
    height: var(--icon-size-xxlarge);
}
