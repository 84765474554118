/* ============================================================================ *\
   Reset -> Embedded Content
\* ============================================================================ */

/**
 * Remove the gap between audio, canvas, iframes, images, videos and the bottom
 * of their containers.
 *
 * @link http://cbrac.co/Q6smqV
 */

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

/**
 * Fluid-width media elements that maintain their aspect ratios.
 */

embed,
img,
object,
video {
    max-width: 100%;
    height: auto;
}

embed,
object {
    height: 100%;
}


/**
 * The <img> element.
 *
 * 1. If images do not load, the `alt` text will be visually offset and more
 *    noticeable.
 * 2. Improve image resize rendering in IE6/7.
 *
 *    @link http://cbrac.co/11ciIZL
 */

 img {
    font-style: italic; /* 1 */
    -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Set maximum height for <svg> elements.
 */

svg {
    max-height: 100%;
}
