/* ============================================================================ *\
   Utilities -> Accessibility
\* ============================================================================ */

/**
 * Hides an element visually but is still accessible by screen readers.
 */

.visually-hidden:not(:focus):not(:active) {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}
