/* ============================================================================ *\
   Components -> Wrapper
\* ============================================================================ */

.wrapper {
    width: 100%;
    max-width: var(--wrapper-max-width);
    margin-left: auto;
    margin-right: auto;
    flex: 1;
}
